<template>
  <div class="w-100" style="max-width:400px">
    <button
        @click="facebookLogin"
        style="background-color: #4267B2;"
        class="btn text-white fw-bold p-3 w-100 d-flex flex-horizontal align-items-center justify-content-center"
    ><i class="bi bi-facebook mx-2 fs-20"></i> {{ translate('front.user_panel.sso.button.facebook') }}
    </button>
    <div class="alert alert-danger my-1" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import eventBus from '../eventBus'
import translatable from '../../vue/mixins/translatable'
import {datalayerPush} from "../../lib/datalayer";

export default {
  name: "FacebookSso",
  mixins: [translatable],
  data() {
    return {
      appId: '571923320904777',
      error: null,
    };
  },
  methods: {
    handleLoginResponse(fbResponse) {
      if (fbResponse.status == 'connected') {
        fetch(window.urls.ssoFacebook, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            credential: fbResponse.authResponse.signedRequest,
          })
        }).then((response) => {
          response.json().then((result) => {
            if (result.success) {
              if (result.accountValidated) {
                eventBus.emit('connectionSuccess');
              } else {
                eventBus.emit('changeActivity', {
                  activity: 'ConfirmActivity',
                  data: {
                    email: result.email,
                  },
                });
              }
            } else {
              if (result.mustRegister) {
                FB.getLoginStatus((fbLoginResponse) => {
                  if (fbLoginResponse.status == 'connected') {
                    let data = {
                      sso: 'facebook',
                      ssoToken: result.profileInfo.accessToken,
                      ssoEmail: result.profileInfo.email,
                      ssoLastname: result.profileInfo.last_name ?? null,
                      ssoFirstname: result.profileInfo.first_name ?? null,
                    };
                    if (result.profileInfo.gender) {
                      data.ssoCivility = result.profileInfo.gender === 'male' ? 'monsieur' : (result.profileInfo.gender === 'female' ? 'madame' : null);
                    }
                    eventBus.emit('changeActivity', {
                      activity: 'RegisterActivity',
                      data: data,
                    });
                  }
                }, true);
              } else {
                this.error = result.error;
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  'event': 'error',
                  'errorType': 'facebookSsoFailed',
                  'errorField': 'credentials',
                  'errorMessage': result.error,
                });
              }
            }
          });
        });
      }
    },
    facebookLogin() {
      datalayerPush('click-login-SSO', {
        method: 'facebook',
      });
      if (window.fbAsyncInit === undefined) {
        window.fbAsyncInit = () => {
          FB.init({
            appId: window.facebookAppId,
            autoLogAppEvents: true,
            version: 'v13.0',
          });
          FB.getLoginStatus((response) => {
            if (response.status == 'connected') {
              this.handleLoginResponse(response);
            } else {
              this.doLoginRequest();
            }
          }, true);
        };
        (function (d, s, id) {
          let js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.crossorigin = "anonymous";
          js.src = "//connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      } else {
        FB.getLoginStatus((response) => {
          if (response.status == 'connected') {
            this.handleLoginResponse(response);
          } else {
            this.doLoginRequest();
          }
        }, true);
      }
    },
    doLoginRequest() {
      FB.login((response) => {
        this.handleLoginResponse(response)
      }, {scope: 'public_profile,email,user_gender'});
    },
  },
}
</script>